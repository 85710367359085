<template>
    <div :class="wrapClass">
        <slide-up-down :class="errorClass" :model-value="error || formStats.error"/>
    </div>
</template>

<script>
    import SlideUpDown from "@shared/components/SlideUpDown";
    export default {
        name: "FormError",
        components: {SlideUpDown},
        inject: ["formStats"],
        props: {
            errorClass: {type: String, default: "text-danger"},
            wrapClass: {type: String, default: "@mt-4"},
            error: {type: String, default: ""},
        }
    };
</script>
